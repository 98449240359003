import { ApiResult } from "@/interfaces/api.interface";
import { Season } from "@/interfaces/season.interface";
import Method from "@/utils/axiosMethods";
import { SeasonDto } from "@/validations/seasons/Season.dto";

const URL_BASE: string = "/api/v1/seasons";

export async function getSeasonsByCompanyId(id: number): Promise<ApiResult<Season[]>> {
  return await Method.get(`${URL_BASE}/company/${id}`);
}

export async function updateSeason(id: number, season: SeasonDto): Promise<ApiResult<Season>> {
  return await Method.patch(`${URL_BASE}/${id}`, season);
}
