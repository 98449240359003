import type { Company, PaginateCompanies } from "@/interfaces/company.interface";
import type { UpdateCompanyDto } from "@/validations/companies/UpdateCompany.dto";
import Method from "@/utils/axiosMethods";
import { ApiResult } from "@/interfaces/api.interface";
import {
  AdminBaseTableCompaniesFiltersDto,
  WrapperStatsTableDto
} from "@/validations/companies/StatsTable.dto";
import {
  AdminModalCompaniesFiltersDto,
  WrapperStatsTableModalDto
} from "@/validations/companies/StatsTableModal.dto";
import { AdminCompaniesFiltersDto } from "@/validations/companies/AdminCompaniesFilters.dto";
import { WrapperAttendanceDto } from "@/validations/companies/Attendance.dto";

const URL_BASE: string = "/api/v1/companies";

export async function getAllCompanies(): Promise<ApiResult<PaginateCompanies>> {
  return await Method.get(URL_BASE);
}

export async function getCompanyById(id: number): Promise<ApiResult<Company>> {
  return await Method.get(`${URL_BASE}/${id}`);
}

export async function getNewCode(companyName: string): Promise<ApiResult<string>> {
  const formattedCompanyName = companyName.replace(/ - | /g, "-");
  return await Method.get(`${URL_BASE}/newcode/${formattedCompanyName}`);
}

export async function updateCompany(
  id: number,
  company: UpdateCompanyDto,
  logo?: File
): Promise<ApiResult<Company>> {
  const formData: FormData = new FormData();
  if (logo) formData.append("logo", logo);
  formData.append("code", company.code);
  formData.append("needPayments", company.needPayments.toString());
  formData.append("maxActivitiesPerParticipant", company.maxActivitiesPerParticipant.toString());
  formData.append("absenceRelaunch", company.absenceRelaunch.toString());
  formData.append("nbAbsencesAuthorized", company.nbAbsencesAuthorized.toString());
  formData.append("mcEnabled", company.mcEnabled.toString());
  formData.append("name", company.name.toString());

  return await Method.patch(`${URL_BASE}/${id}`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export async function archiveCompany(id: number, isArchived: boolean): Promise<ApiResult<Company>> {
  return await Method.patch(`${URL_BASE}/${id}/archive`, { isArchived });
}

export async function getCompanyLogo(id: number): Promise<ApiResult<Blob>> {
  return await Method.get(`${URL_BASE}/${id}/logo`, { responseType: "blob" });
}

export async function getCompanyProgram(id: number): Promise<ApiResult<Blob>> {
  return await Method.get(`${URL_BASE}/${id}/program`, { responseType: "blob" });
}

export async function uploadProgram(companyId: number, program: File): Promise<ApiResult<string>> {
  const formData: FormData = new FormData();
  formData.append("program", program);

  return await Method.post(`${URL_BASE}/program/${companyId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export async function deleteLogoByCompany(companyId: number): Promise<ApiResult<string>> {
  return await Method.patch(`${URL_BASE}/${companyId}/logo`);
}

export async function deleteProgramByCompany(companyId: number): Promise<ApiResult<string>> {
  return await Method.patch(`${URL_BASE}/${companyId}/program`);
}

export async function adminStats(
  filters: AdminBaseTableCompaniesFiltersDto
): Promise<ApiResult<WrapperStatsTableDto>> {
  const params: URLSearchParams = new URLSearchParams();
  params.append("startDate", filters.startDate.toISOString());
  params.append("endDate", filters.endDate.toISOString());

  if (filters.company) params.append("company", filters.company.toString());
  params.append("coaches", filters.coaches.join(","));
  params.append("sessionFull", filters.sessionFull.toString());
  params.append("inactiveSession", filters.inactiveSession.toString());

  return await Method.get(`${URL_BASE}/admin/stats?${params}`);
}

export async function adminStatsModal(
  repetitionId: number,
  filters: AdminModalCompaniesFiltersDto
): Promise<ApiResult<WrapperStatsTableModalDto>> {
  const params: URLSearchParams = new URLSearchParams();

  params.append("startDate", filters.startDate.toISOString());
  params.append("endDate", filters.endDate.toISOString());
  params.append("participantsUnsubscribe", filters.participantsUnsubscribe.toString());

  return await Method.get(`${URL_BASE}/admin/stats/modal/${repetitionId}?${params}`);
}

export async function adminAttendance(
  filters: AdminCompaniesFiltersDto
): Promise<ApiResult<WrapperAttendanceDto>> {
  const params: URLSearchParams = new URLSearchParams();

  params.append("startDate", filters.startDate.toISOString());
  params.append("endDate", filters.endDate.toISOString());

  if (filters.company) params.append("company", filters.company.toString());

  return await Method.get(`${URL_BASE}/admin/attendance?${params}`);
}
